import {IconButton, Typography, useMediaQuery} from "@mui/material";
import Box from "@mui/material/Box";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from "react-responsive-carousel";
import Image from "next/image";
import styles from '../../styles/Testimonials.module.scss'
import {ArrowBackIos, ArrowForwardIos, Star} from "@mui/icons-material";

export default function Testimonials() {
    const matchesMobile = useMediaQuery('(max-width:1000px)');
    const matchesSmallerScreen = useMediaQuery('(max-width:1800px)');

    let usersOnScreenAtTheSameTime = 3

    if(matchesSmallerScreen)
        usersOnScreenAtTheSameTime = 2

    if(matchesMobile)
        usersOnScreenAtTheSameTime = 1

    const peopleReviews = [
        {
            name: 'Dave Schutte',
            position: 'Fire Chief from City of Comanche, Iowa',
            stars: 5,
            review: 'We have been using InstaConference service for 5+ years and probably have saved numerous lives because of it.',
            image: '/images/testimonials/men.svg'
        },
        {
            name: 'Keith A. Norman',
            position: 'Director at Jerseyville Fire Department',
            stars: 5,
            review: 'We use Firebar service created by XOP in Jerseyville, IL. We value their technical assistance and live support during repairs.',
            image: '/images/testimonials/men.svg'
        },
        {
            name: 'Mr. Sukanto Aich',
            position: 'President enterprise limited at TATA services',
            stars: 5,
            review: 'SMS-driven Instant Conferencing enhances our VAS offerings, enabling customers to arrange spontaneous conference calls with on-net and off-net participants.',
            image: '/images/testimonials/men.svg'
        },
        {
            name: 'Steven McCall',
            position: 'Fire Chief, Beardhead Fire Department. Beardhead, CO',
            stars: 5,
            review: 'We switched to using InstaConference after our local phone company stopped offering a similar service. I wish we had known about this service earlier. Its much easier to use and far more reliable.',
            image: '/images/testimonials/men.svg'
        }
    ]


    // split testimonials into chunks by 2 elements and print them in rows
    const peopleReviewsSplit = (arr, size) => {
        const newArr = [];
        for (var i = 0; i < arr.length; i += size) {
            newArr.push(arr.slice(i, i + size));
        }
        return newArr;
    }

    // itireate over the chunks and print them in rows
    const testimonials = peopleReviewsSplit(peopleReviews, usersOnScreenAtTheSameTime).map((row, index) => {
        return (
            <Box className={styles.screenContainer} key={index}>
                {row.map((person, index) => {
                    return (
                        <Box className={styles.personContainer} key={person.name}>
                            <Box className={styles.imageContainer}>
                                <Image src={person.image} width={163} height={169}
                                       alt={person.name}/>
                            </Box>
                            <Box className={styles.text}>
                                <Typography component="h3" className={styles.name}>
                                    {person.name}
                                </Typography>
                                <Typography component="h4" className={styles.position}>
                                    {person.position}
                                </Typography>

                                <Typography component="div" className={styles.review}>
                                    <Box className={styles.stars}>
                                        {[...Array(person.stars)].map((e, i) => {
                                            return <Star key={i} className={styles.star}/>
                                        })}
                                    </Box>
                                    {person.review}
                                </Typography>
                            </Box>
                        </Box>
                    )
                })}
            </Box>
        )
    })


    return (
        <Box className={styles.testimonialsContainer}>

            <Typography component="h3" fontSize={"50px"} color={"primary"} fontWeight={"bold"} textAlign={"center"}>
                What our clients say
            </Typography>

            <Carousel
                showArrows={(usersOnScreenAtTheSameTime === peopleReviews.length) ? false : true}
                infiniteLoop={true}
                showThumbs={false}
                showStatus={false}
                showIndicators={false} // small circles at the bottom
                autoPlay={true}
                interval={20000}
                transitionTime={1000}
                renderArrowPrev={(onClickHandler, hasPrev, label) => {
                    return (usersOnScreenAtTheSameTime !== peopleReviews.length) && (
                        <IconButton size={"large"} onClick={onClickHandler} title={label}>
                            <ArrowBackIos/>
                        </IconButton>
                    )
                }}
                renderArrowNext={(onClickHandler, hasNext, label) => {
                    return (usersOnScreenAtTheSameTime !== peopleReviews.length) && (
                        <IconButton size={"large"} onClick={onClickHandler} title={label}>
                            <ArrowForwardIos/>
                        </IconButton>
                    )
                }}
                className={styles.carousel}
            >
                {testimonials}
            </Carousel>
        </Box>
    )
}