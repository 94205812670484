import styles from '../styles/Home.module.css'
import {Box, Typography} from "@mui/material";
import TextWithSideImage from "../components/shared/TextWithSideImage";
import HomeIntro from "../components/sections/HomeIntro";
import StyledSideImage from "../components/shared/StyledSideImage";
import Testimonials from "../components/shared/Testimonials";
import Link from "next/link";
import Head from "next/head";
import seo from "../constants/seo.json";
import Image from "next/image";

export default function Home() {
    const meta = seo.home;

    return (
        <div className={styles.container}>

            <Head>
                <title>{meta.title}</title>
                <meta name="description" content={meta.description}/>
                <meta name="keywords" content={meta.keywords}/>
            </Head>

            <Box className={styles.bigBlueBackground} />
            {/*    <Image src="/images/header-background.png" width={1245} height={959} alt="home-bg" preload={true} />*/}
            {/*</Box>*/}

            <StyledSideImage side={'left'}/>
            <StyledSideImage side={'right'}/>

            <HomeIntro/>

            <TextWithSideImage textDirection={'right'}
                               primaryText={'Fire Chiefs'}
                               text={'We have saved many lives, thanks to InstaConference service. Earlier I used to use a paging system and it was a nightmare to get the right firemen and EMTs to the target location.'}
                               image={'/images/fire-chiefs.png'}
                               imageAlt={'Fire Chiefs emergency conferencing'}
                               imageWidth={561}
                               imageHeight={332}
            />

            <TextWithSideImage textDirection={'left'}
                               primaryText={'Paramedics'}
                               text={'It is incredible how fast this InstaConference service works. I use speed dial for calling in on my mobile and within seconds I am talking to all of my paramedics.'}
                               image={'/images/paramedics.png'}
                               imageAlt={'Paramedics emergency call'}
                               imageWidth={569}
                               imageHeight={346}
            />

            <TextWithSideImage textDirection={'rigth'}
                               primaryText={'Power Plant Operators'}
                               text={'InstaConference service is ultra-reliable. Using this service we can get all the decision makers into a quick conference which otherwise could take hours. This is a ‘must have’ service for an emergency prone environment like ours.'}
                               image={'/images/power-plant.png'}
                               imageAlt={'Power Plant Emergency strategy'}
                               imageWidth={561}
                               imageHeight={332}
            />

            <TextWithSideImage textDirection={'left'}
                               primaryText={'Electricians'}
                               text={'We operate in an environment in which serious injuries can occur at any time. Saving minutes and seconds can make a difference between if someone lives or dies. That’s how crucial is InstaConference to us.'}
                               image={'/images/electricians.png'}
                               imageAlt={'Electricians emergency'}
                               imageWidth={568}
                               imageHeight={338}
            />


            <Testimonials/>

            <Box className={styles.getStartedContainer}>
                <Typography variant={"h2"} color={"primary"}>Let's Get Started</Typography>
                <Typography variant={"h4"}>
                    <Link href="/contact">Contact Us Now!</Link>
                </Typography>
            </Box>
        </div>
    )
}
