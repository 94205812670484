import {Box, Button, Slide, Typography, useTheme} from "@mui/material";
import PageContainer from "../layouts/PageContainer";
import styles from '../../styles/Home.module.css'
import Link from "next/link";
import Image from "next/image";
import {useIntl} from "react-intl";

export default function HomeIntro() {
    const intl = useIntl();
    const theme = useTheme();

    return (
        <Box className={styles.headerInto}>
            <PageContainer>

                <Box className={styles.headerInfoInner}>
                    {/*<Slide direction={"right"} easing={theme.transitions.easing.easeInOut} timeout={700}>*/}
                        <Box className={styles.headerInfoLeft}>
                            <Typography className={styles.smallColoredText} variant={"h3"}>We Are Here For
                                You</Typography>
                            <Typography className={styles.mainText} variant={"h1"}>Emergency conferencing
                                <br /> for <span>first responders</span>
                            </Typography>
                            <Typography className={styles.mainTextDescription} variant={"h2"}>Make one simple phone call
                                and connect with all your first responders</Typography>

                            <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                                <Button variant={"outlined"} component={Link} href={'/features'} title={'Features list for product'}
                                        className={styles.learnMoreBtn}>
                                    {intl.formatMessage({id: "page.read_more"})}
                                </Button>

                                <Image src={'/images/arrow.svg'} alt={"Arrow pointing to the features button"}
                                       width={170} height={160}
                                       className={styles.arrowPictureElement}/>
                            </Box>
                        </Box>
                    {/*</Slide>*/}
                    <Box className={styles.headerInfoRight}>
                        <Image priority={true} src={'/images/flow-animation-image.png'} width={809} height={636}
                               alt={"Instant Conference first responders conferencing flow"}/>
                    </Box>
                </Box>

            </PageContainer>
        </Box>
    )
}

